import { lazy } from "react";
import { ROUTES } from "../constants/router";
// pages
const Monsters = lazy(() => import("../component/Monsters"));
const Characters = lazy(() => import("../component/Characters"));
const HomePage = lazy(() => import("../component/Home"));
const Story = lazy(() => import("../component/Story"));
const Feature = lazy(() => import("../component/Feature"));
const Gallery = lazy(() => import("../component/Gallery"));

const RoutePage = [
    {// import { loadFull } from "tsparticles";
        path: ROUTES.HOMEPAGE,
        exact: true,
        component: HomePage,
    },
    {
        path: ROUTES.MONSTERS,
        exact: true,
        component: Monsters,
    },
    {
        path: ROUTES.STORY,
        exact: true,
        component: Story,
    },
    {
        path: ROUTES.FEATURE,
        exact: true,
        component: Feature,
    }
    ,
    {
        path: ROUTES.GALLERY,
        exact: true,
        component: Gallery,
    },
    {
        path: ROUTES.CHARACTERS,
        exact: true,
        component: Characters,
    }

];
export default RoutePage;
