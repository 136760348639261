import React from 'react';
import { Suspense } from "react";
import RoutePage from "../../Router/Router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            {RoutePage.map((route) =>
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            )}
          </Switch>
        </Suspense>
      </Router>
    </>
  )
}

export default App
